import React from "react";

function Instagram() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="22 9 20 45" width={"30"} height={"30"}>
      <path d="M31.82 12C13.44 12 12 13.44 12 31.82v.36C12 50.56 13.44 52 31.82 52h.36C50.56 52 52 50.56 52 32.18V32c0-18.548-1.452-20-20-20h-.18zM28 16h8c11.129 0 12 .871 12 12v8c0 11.129-.871 12-12 12h-8c-11.129 0-12-.871-12-12v-8c0-11.129.871-12 12-12zm13.994 4a2 2 0 10.01 3.999 2 2 0 00-.01-3.999zm-10.017 2c-5.522.013-9.99 4.501-9.977 10.023.013 5.522 4.501 9.99 10.023 9.977 5.522-.013 9.99-4.501 9.977-10.023-.013-5.522-4.501-9.99-10.023-9.977zm.01 4a6 6 0 11.025 12.002A6 6 0 0131.986 26z"/>
    </svg>
  );
}

export { Instagram };