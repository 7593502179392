import React from "react";

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1114.00 354.00">
      <g>
        <path d="
          M 1.21 2.25
          C 1.15 2.12 1.12 2.00 1.12 1.88
          C 1.11 1.58 1.30 1.32 1.58 1.23
          C 2.98 0.78 4.12 0.72 5.30 1.79
          C 9.70 5.78 14.14 9.73 18.62 13.63
          C 55.09 45.32 91.51 77.07 127.90 108.86
          C 129.39 110.16 130.08 111.48 130.07 113.58
          C 129.86 157.55 130.01 201.53 130.51 245.50
          C 130.54 247.68 130.44 249.75 130.23 251.72
          C 130.17 252.26 129.54 252.54 129.11 252.21
          C 126.49 250.23 124.06 248.24 121.81 246.23
          C 110.06 235.71 98.24 225.26 86.37 214.89
          C 83.31 212.21 80.77 210.54 80.73 207.38
          C 80.48 185.25 80.33 163.13 80.28 141.00
          C 80.27 137.13 79.95 135.87 76.93 133.23
          C 40.10 101.10 15.53 79.71 3.21 69.06
          C 1.58 67.65 0.99 66.72 0.99 64.43
          C 0.99 44.28 1.01 24.14 1.05 4.00
          C 1.05 3.41 1.10 2.83 1.21 2.25
          Z"
        />
        <path d="
          M 1.71 241.51
          C 1.26 241.12 1.00 240.54 1.00 239.94
          L 1.00 102.24
          C 1.00 101.33 2.02 100.79 2.77 101.30
          C 3.39 101.71 4.09 102.25 4.87 102.90
          C 19.21 114.89 33.54 126.88 47.86 138.89
          C 50.64 141.22 50.39 143.46 50.43 147.53
          C 50.66 169.96 51.00 192.39 51.43 214.82
          C 51.44 215.17 51.56 215.48 51.81 215.74
          C 52.92 216.92 54.50 218.41 56.55 220.20
          C 80.48 241.19 104.45 262.14 128.44 283.05
          C 129.64 284.09 130.16 285.14 130.19 286.82
          C 130.53 307.29 130.58 327.77 130.34 348.25
          C 130.33 349.54 130.16 350.40 129.85 350.85
          C 129.15 351.87 128.33 351.97 127.38 351.15
          L 1.71 241.51
          Z"
        />
        <path d="
          M 342.38 121.59
          C 342.48 121.98 342.93 122.14 343.26 121.91
          C 358.21 111.39 374.71 106.91 392.91 108.79
          C 430.17 112.64 434.22 143.03 434.24 173.60
          C 434.26 201.65 434.25 229.70 434.20 257.75
          C 434.20 261.97 433.36 265.63 428.25 265.66
          C 411.91 265.77 401.49 265.80 397.00 265.75
          C 392.92 265.70 390.87 263.58 390.86 259.38
          C 390.73 228.60 390.70 197.82 390.75 167.04
          C 390.76 157.48 389.21 147.18 378.24 145.12
          C 366.27 142.87 355.49 146.87 345.38 152.78
          C 344.85 153.09 344.53 153.65 344.53 154.26
          C 344.49 189.25 344.48 224.25 344.49 259.25
          C 344.49 261.93 343.47 265.66 340.00 265.70
          C 327.09 265.84 316.18 265.81 307.25 265.62
          C 303.29 265.54 301.31 263.50 301.30 259.50
          C 301.25 211.91 301.22 164.33 301.21 116.75
          C 301.21 112.33 303.91 110.83 308.10 110.70
          C 322.80 110.22 332.35 110.19 336.76 110.62
          C 339.44 110.88 341.12 112.28 341.79 114.81
          C 342.28 116.65 341.80 119.35 342.38 121.59
          Z"
        />
        <path d="
          M 506.59 202.75
          C 506.46 207.20 507.15 211.52 508.64 215.69
          C 515.39 234.63 534.07 233.00 550.19 232.12
          C 563.19 231.41 574.36 231.14 587.25 229.98
          C 590.95 229.65 592.77 232.07 592.77 235.68
          C 592.76 241.89 592.79 248.09 592.84 254.30
          C 592.87 257.97 592.09 260.41 588.43 261.38
          C 575.06 264.92 564.25 266.88 556.00 267.27
          C 537.18 268.17 519.60 269.82 501.59 262.49
          C 475.86 252.03 464.28 228.40 463.73 201.30
          C 463.45 187.39 463.24 176.17 464.84 164.75
          C 468.67 137.43 485.89 116.49 513.24 110.44
          C 530.09 106.71 546.10 107.83 561.25 113.80
          C 586.02 123.56 598.53 150.16 598.84 175.76
          C 598.95 185.03 598.90 191.94 598.68 196.51
          C 598.45 201.20 595.20 201.76 591.05 201.76
          C 563.43 201.77 535.61 201.78 507.60 201.77
          C 507.05 201.77 506.61 202.20 506.59 202.75
          Z
          M 507.58 174.91
          L 555.80 174.99
          C 556.24 174.99 556.60 174.64 556.60 174.19
          L 556.61 169.56
          C 556.64 155.23 546.50 143.60 533.97 143.57
          L 529.53 143.57
          C 517.00 143.54 506.82 155.14 506.79 169.48
          L 506.78 174.11
          C 506.78 174.55 507.14 174.91 507.58 174.91
          Z"
        />
        <path d="
          M 1108.63 223.49
          C 1102.48 243.79 1089.41 258.87 1068.49 264.98
          C 1053.52 269.36 1038.11 269.54 1022.26 265.53
          C 1002.04 260.41 988.75 247.43 981.42 228.47
          C 978.37 220.56 977.48 213.08 976.52 205.32
          C 975.45 196.56 976.02 187.20 976.02 178.36
          C 976.02 174.52 976.78 170.38 977.16 166.00
          C 977.53 161.62 978.71 156.46 980.70 150.53
          C 987.79 129.38 1001.93 116.16 1023.12 110.87
          C 1035.79 107.71 1049.09 107.53 1063.00 110.33
          C 1089.05 115.57 1105.70 134.76 1110.20 160.53
          C 1111.11 165.72 1111.97 170.48 1112.01 175.86
          C 1112.17 195.19 1113.19 208.45 1108.63 223.49
          Z
          M 1020.96 165.76
          C 1020.31 168.29 1019.98 170.46 1019.98 172.25
          C 1019.96 182.13 1019.97 192.01 1020.00 201.88
          C 1020.01 204.96 1020.27 207.69 1020.78 210.06
          C 1022.99 220.44 1027.25 228.35 1038.52 230.83
          C 1054.96 234.44 1066.57 224.03 1067.96 207.99
          C 1068.97 196.34 1069.03 184.18 1068.15 171.53
          C 1067.82 166.73 1066.97 162.97 1065.23 158.79
          C 1062.13 151.30 1056.54 147.04 1048.47 146.02
          C 1044.54 145.53 1041.63 145.46 1039.74 145.82
          C 1028.01 148.06 1023.70 155.14 1020.96 165.76
          Z"
        />
        <path d="
          M 671.54 120.16
          C 671.64 121.09 672.07 121.28 672.82 120.72
          C 683.11 113.13 694.96 108.54 707.75 108.73
          C 712.45 108.81 713.72 111.32 713.74 115.76
          C 713.77 123.37 713.77 130.99 713.74 138.62
          C 713.72 141.59 712.80 144.28 709.51 144.28
          C 696.40 144.28 684.40 147.33 673.51 153.42
          C 672.69 153.87 672.28 154.57 672.28 155.51
          C 672.25 189.16 672.26 222.63 672.31 255.92
          C 672.32 260.19 672.54 265.92 666.75 265.88
          C 657.42 265.83 648.08 265.75 638.75 265.66
          C 633.95 265.61 628.94 265.71 628.92 259.25
          C 628.69 187.30 628.65 139.80 628.82 116.75
          C 628.85 112.50 631.16 110.38 635.76 110.38
          C 651.83 110.38 661.99 110.43 666.25 110.54
          C 672.35 110.69 671.10 116.12 671.54 120.16
          Z"
        />
        <path d="
          M 825.74 262.94
          C 816.80 264.49 807.72 265.59 798.50 266.24
          C 775.16 267.88 752.39 260.69 742.12 237.89
          C 739.41 231.88 737.78 225.91 737.23 219.99
          C 736.85 215.91 736.13 212.09 736.12 208.49
          C 736.09 198.61 736.08 188.73 736.11 178.85
          C 736.12 177.29 736.28 175.15 736.61 172.43
          C 737.46 165.30 737.91 159.40 740.41 151.52
          C 750.83 118.56 781.92 111.32 812.68 110.49
          C 831.28 109.98 845.81 110.75 862.25 113.50
          C 864.93 113.95 871.09 115.16 871.08 118.75
          C 870.99 169.25 870.99 219.75 871.08 270.25
          C 871.09 274.46 870.17 279.95 868.34 286.74
          C 859.46 319.69 823.03 327.19 794.00 324.77
          C 787.57 324.23 783.50 323.87 781.77 323.70
          C 767.34 322.25 753.00 320.17 738.75 317.46
          C 734.88 316.73 731.43 315.74 732.36 310.77
          C 733.69 303.64 735.03 296.51 736.38 289.38
          C 736.69 287.74 737.98 286.38 740.23 285.29
          C 742.00 284.43 747.27 285.83 748.90 286.06
          C 752.09 286.51 755.28 286.99 758.47 287.48
          C 762.27 288.06 766.09 288.53 769.92 288.88
          C 769.99 288.89 772.20 289.18 776.54 289.75
          C 779.55 290.15 782.50 289.92 785.46 290.38
          C 794.74 291.84 803.82 290.95 812.70 287.71
          C 818.86 285.46 823.07 281.45 825.32 275.69
          C 826.72 272.11 826.51 267.69 826.57 263.66
          C 826.58 263.21 826.18 262.87 825.74 262.94
          Z
          M 781.03 173.91
          C 780.78 176.57 780.03 179.63 780.01 182.75
          C 779.94 194.66 779.52 206.11 784.86 216.88
          C 790.33 227.94 800.60 229.93 811.97 228.34
          C 816.71 227.67 821.56 227.54 826.22 226.36
          C 826.39 226.32 826.51 226.17 826.51 225.99
          L 826.46 147.50
          C 826.45 146.98 826.03 146.56 825.52 146.57
          C 822.16 146.59 818.82 146.38 815.50 145.95
          C 812.96 145.62 808.04 145.88 800.74 146.73
          C 787.86 148.24 782.06 162.66 781.03 173.91
          Z"
        />
        <rect x="222.78" y="110.55" width="43.48" height="155.20" rx="5.74"/>
        <rect x="903.06" y="110.55" width="43.84" height="155.16" rx="5.80"/>
        <path d="
          M 557.40 291.11
          L 549.57 298.72
          C 549.46 298.84 549.30 298.90 549.13 298.90
          L 544.64 298.98
          C 544.08 298.99 543.77 298.35 544.13 297.92
          L 550.55 290.21
          C 550.68 290.07 550.86 289.98 551.06 289.98
          L 556.95 290.00
          C 557.53 290.00 557.81 290.71 557.40 291.11
          Z"
        />
        <path d="
          M 316.24 332.88
          L 309.38 332.96
          C 308.49 332.97 308.04 332.53 308.03 331.63
          L 308.02 327.77
          C 308.01 326.95 308.42 326.54 309.23 326.53
          L 324.26 326.36
          C 324.64 326.35 324.95 326.65 324.96 327.02
          C 325.02 329.46 325.12 331.78 324.66 334.31
          C 324.16 337.07 324.07 340.03 323.02 342.45
          C 317.14 356.04 296.36 355.76 290.85 342.16
          C 290.09 340.30 289.09 336.41 289.05 334.21
          C 288.80 322.42 287.93 308.57 301.98 304.52
          C 304.19 303.89 307.66 303.92 312.37 304.62
          C 316.70 305.26 320.15 307.86 322.74 312.43
          C 323.49 313.76 323.16 314.73 321.75 315.34
          L 318.98 316.53
          C 317.72 317.06 316.73 316.76 316.00 315.61
          C 311.99 309.31 301.27 308.83 298.20 316.67
          C 296.28 321.56 297.12 327.57 297.15 332.73
          C 297.17 336.48 297.66 339.23 298.63 340.99
          C 302.70 348.39 315.14 346.88 316.77 338.26
          C 316.92 337.47 316.94 335.87 316.84 333.45
          C 316.83 333.06 316.63 332.87 316.24 332.88
          Z"
        />
        <rect x="218.98" y="304.60" width="8.04" height="47.32" rx="1.54"/>
        <path d="
          M 248.71 318.74
          C 248.26 319.25 248.03 319.92 248.04 320.75
          C 248.14 329.24 248.19 339.14 248.19 350.44
          C 248.19 351.43 247.70 351.91 246.71 351.90
          L 242.53 351.84
          C 241.50 351.82 240.99 351.30 240.99 350.28
          L 241.01 305.52
          C 241.01 305.21 241.16 305.03 241.45 304.97
          C 243.62 304.50 245.80 304.44 248.00 304.81
          C 248.52 304.90 248.91 305.17 249.18 305.62
          L 267.47 336.66
          C 267.57 336.84 267.75 336.97 267.96 337.00
          C 268.59 337.11 268.90 336.78 268.90 336.00
          C 268.89 326.02 268.88 316.17 268.87 306.46
          C 268.87 305.35 269.43 304.77 270.54 304.73
          L 274.26 304.59
          C 275.42 304.55 276.00 305.11 276.00 306.27
          L 276.00 350.54
          C 276.00 351.38 275.58 351.84 274.73 351.92
          C 273.21 352.06 269.39 352.47 268.39 350.87
          C 261.80 340.28 255.40 329.59 249.17 318.78
          C 249.04 318.55 248.88 318.53 248.71 318.74
          Z"
        />
        <path d="
          M 388.04 319.84
          C 387.86 320.69 387.78 321.82 387.80 323.25
          C 387.95 332.24 387.95 341.37 387.81 350.65
          C 387.80 351.39 387.19 351.99 386.45 351.98
          L 382.38 351.94
          C 381.51 351.93 381.08 351.50 381.08 350.63
          L 381.09 305.40
          C 381.09 305.14 381.28 304.91 381.54 304.87
          C 383.73 304.52 385.90 304.54 388.06 304.93
          C 388.38 304.98 388.63 305.15 388.80 305.44
          L 407.31 336.70
          C 408.43 338.59 408.99 338.44 408.99 336.24
          L 408.99 306.17
          C 408.99 305.11 409.52 304.59 410.58 304.60
          L 414.48 304.66
          C 415.51 304.67 416.03 305.20 416.03 306.23
          L 416.10 350.47
          C 416.10 351.35 415.66 351.80 414.78 351.83
          L 409.24 351.99
          C 408.86 352.00 408.50 351.81 408.30 351.48
          L 389.33 319.62
          C 388.72 318.59 388.29 318.66 388.04 319.84
          Z"
        />
        <rect x="430.00" y="304.63" width="8.10" height="47.32" rx="1.65"/>
        <rect x="-3.98" y="-23.71" transform="translate(546.24,328.24) rotate(0.1)" width="7.96" height="47.42" rx="1.53"/>
        <path d="
          M 589.77 342.65
          C 589.63 342.25 589.25 341.99 588.83 341.99
          L 572.30 341.95
          C 571.73 341.95 571.36 342.22 571.17 342.75
          L 568.38 350.63
          C 568.05 351.57 567.39 352.03 566.40 352.00
          L 561.77 351.90
          C 560.78 351.88 560.45 351.40 560.78 350.47
          L 576.70 305.71
          C 576.93 305.07 577.53 304.65 578.21 304.66
          L 583.61 304.79
          C 583.97 304.80 584.29 305.03 584.41 305.37
          C 588.44 316.76 592.43 327.95 596.37 338.94
          C 597.79 342.91 599.03 346.81 600.08 350.64
          C 600.33 351.57 599.98 352.02 599.03 351.99
          L 594.31 351.88
          C 593.49 351.86 592.94 351.46 592.66 350.69
          L 589.77 342.65
          Z
          M 574.08 333.88
          C 573.88 334.45 574.31 335.04 574.91 335.04
          L 586.09 335.06
          C 586.69 335.06 587.12 334.47 586.92 333.90
          L 581.36 317.61
          C 581.09 316.82 579.97 316.81 579.70 317.61
          L 574.08 333.88
          Z"
        />
        <path d="
          M 368.26 345.89
          L 368.04 350.88
          C 368.01 351.46 367.53 351.91 366.96 351.91
          L 339.07 351.91
          C 338.47 351.91 337.99 351.43 337.99 350.83
          L 337.96 305.92
          C 337.96 305.32 338.44 304.84 339.04 304.84
          L 367.02 304.76
          C 367.61 304.76 368.09 305.24 368.10 305.83
          L 368.14 310.55
          C 368.15 311.15 367.66 311.64 367.06 311.64
          L 346.20 311.65
          C 345.59 311.65 345.11 312.14 345.12 312.75
          L 345.31 323.65
          C 345.32 324.25 345.80 324.72 346.40 324.72
          L 364.27 324.64
          C 364.88 324.64 365.37 325.14 365.36 325.75
          L 365.26 330.88
          C 365.25 331.47 364.77 331.94 364.18 331.94
          L 346.19 331.93
          C 345.59 331.93 345.10 332.43 345.11 333.03
          L 345.32 343.73
          C 345.33 344.32 345.81 344.79 346.40 344.79
          L 367.18 344.76
          C 367.80 344.76 368.29 345.27 368.26 345.89
          Z"
        />
        <path d="
          M 460.27 333.21
          L 460.31 343.55
          C 460.32 344.26 460.89 344.83 461.60 344.82
          L 481.97 344.74
          C 482.67 344.74 483.25 345.31 483.26 346.01
          L 483.31 350.62
          C 483.32 351.33 482.75 351.91 482.04 351.91
          L 453.32 351.96
          C 452.61 351.96 452.04 351.39 452.04 350.68
          L 452.04 306.04
          C 452.04 305.33 452.61 304.76 453.32 304.76
          L 482.12 304.73
          C 482.84 304.73 483.42 305.32 483.40 306.04
          L 483.32 310.42
          C 483.30 311.12 482.73 311.68 482.03 311.68
          L 461.44 311.65
          C 460.72 311.65 460.15 312.23 460.15 312.95
          L 460.28 323.49
          C 460.28 324.20 460.86 324.76 461.57 324.76
          L 478.22 324.61
          C 478.93 324.61 479.52 325.19 479.51 325.90
          L 479.48 330.69
          C 479.47 331.39 478.90 331.96 478.20 331.96
          L 461.55 331.92
          C 460.84 331.92 460.26 332.50 460.27 333.21
          Z"
        />
        <path d="
          M 503.37 333.50
          L 503.52 350.30
          C 503.53 351.18 502.83 351.90 501.95 351.91
          L 497.02 351.98
          C 496.06 352.00 495.27 351.22 495.27 350.26
          L 495.22 305.97
          C 495.22 305.32 495.75 304.79 496.41 304.78
          C 502.72 304.76 508.92 304.77 515.00 304.81
          C 520.33 304.85 524.30 306.86 526.90 310.85
          C 531.40 317.77 529.34 327.47 521.64 331.01
          C 520.91 331.35 520.60 332.23 520.97 332.95
          L 530.03 350.70
          C 530.31 351.25 529.91 351.91 529.29 351.92
          L 522.77 351.97
          C 522.25 351.98 521.77 351.68 521.54 351.21
          L 513.05 333.74
          C 512.77 333.18 512.20 332.82 511.57 332.81
          L 504.06 332.81
          C 503.59 332.81 503.36 333.04 503.37 333.50
          Z
          M 503.37 312.98
          L 503.35 324.30
          C 503.35 325.08 503.97 325.71 504.75 325.71
          L 514.66 325.72
          C 518.41 325.73 521.45 322.83 521.46 319.25
          L 521.46 318.11
          C 521.47 314.52 518.43 311.61 514.68 311.60
          L 504.77 311.59
          C 504.00 311.59 503.37 312.21 503.37 312.98
          Z"
        />
      </g>
    </svg>
  );
}

export { Logo };