import React from "react";

function Footer() {
  return (
    <footer>
      <div className="copyright">© Inergio. All rights reserved.</div>
      <div className="creators">
        Design:
        <a
          href="https://www.linkedin.com/in/tomas-britos/"
          target="_blank"
          rel="noreferrer"
        >
          Tomás Britos
        </a>
        |
        <a
          href="https://www.linkedin.com/in/carlos-britos-398aa620a/"
          target="_blank"
          rel="noreferrer"
        >
          Carlos Britos
        </a>
      </div>
    </footer>
  );
}

export { Footer };
